import { lazy, Suspense } from "react";
import { createBrowserRouter } from "react-router-dom";
import { AppRoute } from "../types";

const LazyLoad = (path: string) => {
  const Comonent = lazy(() => import(`../pages${path}`));
  return (
    <Suspense fallback={<div>Loding...</div>}>
      <Comonent />
    </Suspense>
  );
};

const routes: AppRoute[] = [
  {
    path: "/",
    element: LazyLoad("/Home"),
  },
  {
    path: "*",
    element: LazyLoad("/NotFound"),
  },
];

const router = createBrowserRouter(routes);

export default router;
